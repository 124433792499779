import BigNumber from 'bignumber.js'
import { NetworkSubgraphUrl } from '../consts'

export async function q(url, { query, variables, transform }) {
    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    })

    const json = await resp.json()

    if (typeof transform === 'function') {
        return transform(json)
    }

    return json
}

/**
 * Fetches Sponsorships and calculates global APY and the number
 * of staked DATA tokens.
 */
export async function getStakingStats() {
    const batchSize = 999

    let totalStakeBN = new BigNumber(0)

    let totalEarningStakeBN = new BigNumber(0)

    let totalYearlyPayoutBN = new BigNumber(0)

    let page = 0

    for (;;) {
        const {
            data: { sponsorships },
        } = await q(NetworkSubgraphUrl, {
            query: `
                query GetSponsorships($first: Int!, $skip: Int!) {
                    sponsorships(first: $first, skip: $skip, where: { totalStakedWei_gt: "0" }) {
                        isRunning
                        remainingWei
                        spotAPY
                        totalStakedWei
                    }
                }
            `,
            variables: {
                first: batchSize + 1,
                skip: page * batchSize,
            },
        })

        for (const { totalStakedWei, spotAPY, isRunning, remainingWei } of sponsorships) {
            const totalStakedWeiBN = new BigNumber(totalStakedWei)

            totalStakeBN = totalStakeBN.plus(totalStakedWeiBN)

            const remainingWeiBN = new BigNumber(remainingWei)

            if (isRunning && remainingWeiBN.gt(0)) {
                totalEarningStakeBN = totalEarningStakeBN.plus(totalStakedWeiBN)

                totalYearlyPayoutBN = totalYearlyPayoutBN.plus(
                    totalStakedWeiBN.multipliedBy(new BigNumber(spotAPY)),
                )
            }
        }

        if (sponsorships.length <= batchSize) {
            /**
             * Recent batch was the last batch.
             */
            break
        }

        page = page + 1
    }

    return {
        /**
         * APY is calculated using earning sponsorships. Total yearly payout made
         * by earning sponsorships divided by sum of earning stakes.
         */
        apy: totalYearlyPayoutBN.dividedBy(totalEarningStakeBN).multipliedBy(100).toNumber(),
        /**
         * TVL on the other hand is a sum of all staked tokens, earning or not,
         * which is more true after all.
         */
        tvl: totalStakeBN.dividedBy(10 ** 18).toNumber(),
    }
}
